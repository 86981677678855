.custom-slider .rs-slider-bar {
  height: 2px;
}

.custom-slider .rs-slider-bar .rs-slider-progress-bar {
  background-color: #017A49;
  height: 2px;
}

.custom-slider .rs-slider-handle {
  top: -7px;
  background-color: #017A49;
  text-align: center;
  padding: 3px;
  margin-left: -8px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.custom-slider .rs-slider-handle:hover {
  box-shadow: 0 0 0 8px rgb(52 152 255 / 25%);
}

.custom-slider .rs-slider-handle::before {
  display: none;
}

.custom-slider.rs-slider-dragging .rs-slider-handle {
  box-shadow: none;
  transform: scale(1.2);
}

.custom-slider.rs-slider-dragging .rs-slider-handle:hover {
  box-shadow: none;
  transform: scale(1.2);
}
